<template>
  <div>
    <el-row>
      <el-button
        size="small"
        type="primary"
        v-has="'add'"
        icon="el-icon-plus"
        @click="handleOperate('add')"
        >新增</el-button
      >
      <el-button
        size="small"
        type="primary"
        v-has="'copy'"
        icon="el-icon-delete"
        @click="activeId = ''"
        >清除选中组件</el-button
      >
      <el-button
        size="small"
        type="primary"
        v-has="'copy'"
        icon="el-icon-document-copy"
        @click="handleCopyMarket()"
        >一键复制组件</el-button
      >
    </el-row>
    <el-row :gutter="6">
      <el-col :span="24">
        <el-form :inline="true" label-suffix=":">
          <el-form-item label="组件id" class="demo-form-inline headLabel"
            ><el-input
              size="small"
              v-model="queryModule.assemblyId"
              placeholder="请输入组件id"
              @keydown.native.enter="search()"
              :clearable="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="组件名称" class="demo-form-inline headLabel"
            ><el-input
              size="small"
              v-model="queryModule.assemblyName"
              placeholder="请输入组件名称"
              @keydown.native.enter="search()"
            ></el-input>
          </el-form-item>
          <el-form-item label="组件描述" class="demo-form-inline"
            ><el-input
              size="small"
              v-model="queryModule.description"
              placeholder="请输入组件描述"
              @keydown.native.enter="search()"
            ></el-input
          ></el-form-item>
          <el-form-item label="组件类型" class="demo-form-inline">
            <el-select
              filterable
              clearable
              size="small"
              v-model="queryModule.assemblyType"
              placeholder="请选择组件类型"
            >
              <el-option
                v-for="i in assemblyTypeList"
                :key="i.assemblyType"
                :label="i.typeName"
                :value="i.assemblyType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组件样式" class="demo-form-inline">
            <el-select
              filterable
              clearable
              size="small"
              v-model="queryModule.assemblyStyle"
              placeholder="请选择组件样式"
              class="headtab"
            >
              <el-option
                v-for="i in assemblyStyleList"
                :key="i.assemblyStyle"
                :label="i.styleName"
                :value="i.assemblyStyle"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="search()"
            >查询</el-button
          ></el-form
        >
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="{ ...queryModule, list }"
      @page-change="handleChangePage"
    >
      <template slot="id" slot-scope="{ row }">
        <el-radio
          v-model="activeId"
          :label="row.assemblyId"
          style="margin-left: 5px"
          >{{ null }}</el-radio
        >
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-tag type="success" v-if="row.status === 1">使用中</el-tag>
        <el-tag type="info" v-else>未使用</el-tag>
      </template>
      <el-table-column label="操作" width="180px" align="center" fixed="right">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            v-has="'deploy'"
            @click="handleOperate('deploy', row)"
            >部署</el-button
          >
          <el-button
            type="text"
            v-has="'language'"
            :disabled="row.showClient == 2"
            @click="handleOperate('internation', row)"
            >国际化</el-button
          >
          <el-button
            type="text"
            v-has="'edit'"
            style="margin-right: 10px"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            v-has="'delete'"
            @click="handleClickOnOff(row)"
            class="colorRed"
          >
            <span style="color: red">删除</span>
          </el-button>
        </template>
      </el-table-column>
    </PageTable>
    <!-- 组件信息 新增/编辑 弹框 -->
    <EditorDialog
      :isShow.sync="editorDialogShow"
      :type="operationType"
      :row="detailData"
    />
    <!-- 国际化弹框 -->
    <InternationDialog
      :isShow.sync="internationDialogShow"
      :type="operationType"
      :row="detailData"
    />
    <!--部署弹框 -->
    <!-- <DeployDialog
      v-if="deployDialogShow"
      :isShow.sync="deployDialogShow"
      :row="detailData"
    /> -->
    <!-- 专栏类型部署弹框 -->
    <!-- <SpecialDialog
      :dragList="dragList"
      :languageSelectList="languageSelectList"
      @updataClick="updataClick"
      :isShow.sync="specialDeploy"
      :row="detailData"
    /> -->
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import PageTable from "@/components/PageTable.vue";
import InternationDialog from "./components/InternationDialog.vue";
import EditorDialog from "./components/EditorDialog.vue";
// import SpecialDialog from "./components/compon/SpecialDialog.vue";
import { queryAssemblyStyleList } from "@/api/componentManage";
// import DeployDialog from "./components/DeployDialog.vue";
import { mapGetters } from "vuex";
import {
  queryAssemblyList,
  queryAssemblyTypeList,
  insertAssembly,
  queryLanguageList,
  deleteAssembly,
  // queryAssemblyPageList,
} from "@/api/componentManage";
export default {
  name: "componentManage",
  components: {
    PageTable,
    InternationDialog,
    EditorDialog,
    // SpecialDialog,
  },
  computed: {
    ...mapGetters(["languageList"]),
  },
  watch: {
    "queryModule.assemblyType": {
      handler: function (val) {
        this.queryModule.assemblyStyle = "";
        queryAssemblyStyleList({ assemblyType: val }).then((res) => {
          this.assemblyStyleList = res.data;
          this.search();
        });
      },
      immediate: true,
    },
    "queryModule.assemblyStyle"() {
      this.search();
    },
  },
  data() {
    return {
      columns: [
        { slot: "id", align: "center", width: 45 },
        { key: "assemblyId", label: "ID", align: "center" },
        { key: "assemblyName", label: "组件名称", align: "center" },
        { key: "typeName", label: "组件类型", align: "center" },
        { key: "styleName", label: "组件样式", align: "center" },
        { key: "description", label: "组件描述", align: "center" },
        { key: "createTime", label: "创建时间", align: "center", width: 140 },
        { key: "modifyTime", label: "修改时间", align: "center", width: 140 },
        // { slot: "status", label: "状态", align: "center" },
      ],
      queryModule: {
        count: true,
        assemblyId: "",
        assemblyName: "",
        description: "",
        assemblyType: "",
        assemblyStyle: "",
        styleName: "",
        page: 1,
        size: 10,
        total: 0,
      },
      activeId: "",
      list: [],
      operationType: "", // 记录当前操作模式新建-add -edit
      detailData: {},
      internationDialogShow: false,
      editorDialogShow: false,
      deployDialogShow: false,
      assemblyTypeList: [], // 组件类型选项列表
      assemblyStyleList: [],
      // specialDeploy: false, //专栏类型部署弹框
      // dragList: [],
      // languageSelectList: [],
      // arr: [{ laguage: "zh_cn", list: [] }],
      // language: [
      //   { language: "ru", languageName: "俄文" },
      //   { language: "zh_cn", languageName: "中文" },
      // ],
    };
  },
  created() {
    this.$store.dispatch("updateLanguageList");
    this.init();
  },
  methods: {
    init() {
      this.getDataList();
      // 获取组件类型
      queryAssemblyTypeList({}).then((res) => {
        this.assemblyTypeList = res.data;
      });
    },
    search() {
      this.queryModule.page = 1;
      this.queryModule.total = 0;
      this.getDataList();
    },
    handleChangePage({ page, size }) {
      this.queryModule.page = page;
      this.queryModule.size = size;
      this.getDataList();
    },

    // 获取列表数据
    getDataList() {
      queryAssemblyList(this.queryModule).then((res) => {
        const { list = [], total } = res.data;
        this.list = list;
        this.queryModule.total = total;
      });
    },
    handleClickOnOff(row) {
      const desc = `确定删除吗？`;
      this.$confirm(desc, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelete(row);
        })
        .catch(() => {});
    },
    handleDelete(row) {
      deleteAssembly({ assemblyId: row.assemblyId }).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.getDataList(); // 成功后刷新列表
        }
      });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      if (operationType === "delete") {
        this.handleDelete(row);
      } else if (operationType === "add") {
        this.editorDialogShow = true;
        this.detailData = null;
      } else if (operationType === "edit") {
        this.editorDialogShow = true;
        this.detailData = { ...row };
      } else if (operationType === "internation") {
        this.internationDialogShow = true;
        this.detailData = { ...row };
      } else if (operationType === "deploy") {
        // 这中间是原有方法 没有单独做专栏模块
        queryLanguageList({ assemblyId: row.assemblyId }).then((res) => {
          if (!res.data.length) {
            this.$message.warning("请先配置国际化语言");
            return false;
          }
          this.$router.push({
            // path: "/business/contentModule/componentManage/deploy",
            // query: {
            //   type: row.assemblyType,
            //   assemblyId: row.assemblyId,
            //   assemblyName: row.assemblyName,
            //   styleName: row.styleName,
            //   num: row.num,
            // },
            // 多个部署页面打开时，切换会出现path参数丢失问题，所以用name传参
            name: "ComponentManageDeploy",
            params: {
              assemblyId: row.assemblyId,
              assemblyType: row.assemblyType,
              assemblyStyle: row.assemblyStyle,
              showClient: row.showClient,
            },
          });
        });
        // 这中间是原有方法没有单独做专栏模块
        // 这一块是单独做专栏模块部署判断
        // if (row.assemblyStyle == 72) {
        //   if (row.showClient == 1) {
        //     queryLanguageList({ assemblyId: row.assemblyId }).then((respon) => {
        //       if (respon.data.length <= 0) {
        //         this.$message.warning("请先配置国际化语言");
        //         return;
        //       }
        //       this.languageSelectList = respon.data;
        //       this.getConfigDetail(row, respon.data);
        //     });
        //   } else {
        //     this.getConfigDetail(row);
        //   }
        // } else {
        //   // queryLanguageList({ assemblyId: row.assemblyId }).then((respon) => {
        //   //   if (!respon.data.length) {
        //   //     this.$message.warning("请先配置国际化语言");
        //   //     return false;
        //   //   }
        //   //   this.languageSelectList = respon.data;

        //   //   queryAssemblyPageList({ assemblyId: row.assemblyId, size: 300 }).then(
        //   //     (res) => {
        //   //       this.dragList = res.data.list || [];
        //   //       this.detailData = { ...row };
        //   //       this.specialDeploy = true;
        //   //       return;
        //   //     }
        //   //   );
        //   // });
        //   // return;
        //   queryLanguageList({ assemblyId: row.assemblyId }).then((res) => {
        //     if (!res.data.length) {
        //       this.$message.warning("请先配置国际化语言");
        //       return false;
        //     }
        //     this.$router.push({
        //       // path: "/business/contentModule/componentManage/deploy",
        //       // query: {
        //       //   type: row.assemblyType,
        //       //   assemblyId: row.assemblyId,
        //       //   assemblyName: row.assemblyName,
        //       //   styleName: row.styleName,
        //       //   num: row.num,
        //       // },
        //       // 多个部署页面打开时，切换会出现path参数丢失问题，所以用name传参
        //       name: "ComponentManageDeploy",
        //       params: {
        //         assemblyId: row.assemblyId,
        //         assemblyType: row.assemblyType,
        //         assemblyStyle: row.assemblyStyle,
        //       },
        //     });
        //   });
        // }
        // 这一块是单独做专栏模块部署判断
      }
    },
    handleCopyMarket() {
      if (!this.activeId) {
        this.$message.warning("请勾选需要复制的组件");
        return;
      }
      let row = this.list.find((item) => item.assemblyId === this.activeId);
      let params = { ...row };
      params.assemblyName = `复制-${params.assemblyName}`;
      insertAssembly(params).then((res) => {
        this.$message.success("复制成功");
        this.search();
        this.activeId = "";
      });
    },
    // obtainArray(num) {
    //   const newElement = {
    //     hdImageUrl: "",
    //     pageName: "",
    //     imageDesc: "",
    //     dragList: [],
    //   };
    //   const numElementsToAdd = num;
    //   let pushList = [];
    //   for (let i = 0; i < numElementsToAdd; i++) {
    //     pushList.push({ ...newElement });
    //   }
    //   return pushList;
    // },
    // getConfigDetail(row, arr) {
    //   queryAssemblyPageList({
    //     assemblyId: row.assemblyId,
    //     size: 300,
    //   }).then((res) => {
    //     // let list =
    //     //   res.data.list.length > 0
    //     //     ? res.data.list
    //     //     : [
    //     //         {
    //     //           list: [
    //     //             {
    //     //               hdImageUrl: "",
    //     //             },
    //     //           ],
    //     //         },
    //     //       ];
    //     // this.dragList = list;
    //     let listArr = [];
    //     if (res.data.list.length > 0) {
    //       // this.dragList = res.data.list;
    //       listArr = res.data.list;
    //       if (arr && listArr.length != arr.length) {
    //         let pushList = this.obtainArray(row.num); //没有部署时 添加数据结构
    //         arr.forEach((lang) => {
    //           if (!listArr.some((item) => item.language === lang.language)) {
    //             listArr.push({
    //               assemblyId: row.assemblyId,
    //               language: lang.language,
    //               languageName: lang.languageName,
    //               list: pushList,
    //             });
    //           }
    //         });
    //       }
    //     } else {
    //       // let num = row.num;
    //       // for (let i = 0; i < num; i++) {
    //       //   listArr.push({
    //       //     list: [
    //       //       { hdImageUrl: "", pageName: "", imageDesc: "", dragList: [] },
    //       //     ],
    //       //   });
    //       // }
    //       let pushList = this.obtainArray(row.num); //没有部署时 添加数据结构
    //       let num = arr ? arr.length : 1;
    //       for (let i = 0; i < num; i++) {
    //         listArr.push({
    //           assemblyId: row.assemblyId,
    //           language: arr ? arr[i].language : "",
    //           languageName: arr ? arr[i].languageName : "",
    //           list: pushList,
    //           // list: [
    //           //   // { hdImageUrl: "", pageName: "", imageDesc: "", dragList: [] },
    //           // ],
    //         });
    //       }
    //       // const newElement = {
    //       //   hdImageUrl: "",
    //       //   pageName: "",
    //       //   imageDesc: "",
    //       //   dragList: [],
    //       // };
    //       // const numElementsToAdd = row.num;
    //       // listArr.forEach((itemArr) => {
    //       //   for (let i = 0; i < numElementsToAdd; i++) {
    //       //     itemArr.list.push({ ...newElement });
    //       //   }
    //       // });
    //     }
    //     this.dragList = listArr;
    //     this.detailData = { ...row };
    //     this.specialDeploy = true;
    //   });
    // },
    // updataClick(val) {
    //   let index = this.dragList.findIndex(
    //     (item) => item.language == val.oldValueLanguage
    //   );
    //   this.$set(this.dragList[index], "list", val.arr);
    // },
  },
};
</script>
<style scoped lang="scss">
.headtab {
  margin: 0 10px;
}
</style>
